import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePrefs } from 'ws-scripts/modules/common';
import NavFragment from './NavFragment';
import MenuLink from './MenuLink';
import MMColumnItem from './MMColumnItem';
import NavIcon from './NavIcon';

const MMColumnSection = ({ itemsData, topMenuItemIndex, headerLabel }) => {
	const { childListLinkClasses } = usePrefs();
	const [isNavLink, setIsNavLink] = useState(false);

	return (
		<MMColumnItem headerLabel={headerLabel}>
			<ul className={`${isNavLink ? 'nav-links' : 'nav-fragment'}`}>
				{itemsData.map((child, i) => {
					if (child?.embedded) {
						return (
							<NavFragment
								url={child.url}
								topMenuItemIndex={topMenuItemIndex}
								key={`${child.label}–${topMenuItemIndex}-0`}
								setIsNavLink={setIsNavLink}
								fragmentId={child.id}
								fragmentUrl={child.url}
							/>
						);
					} else {
						const childKey = `${child.label}-${topMenuItemIndex}-${i}`;
						return (
							<MenuLink
								childListLinkClasses={`child ${childListLinkClasses}`}
								url={child.url}
								target={child.target}
								key={childKey}
								setIsNavLink={setIsNavLink}
							>
								<NavIcon label={child.label} />
							</MenuLink>
						);
					}
				})}
			</ul>
		</MMColumnItem>
	);
};

MMColumnSection.propTypes = {
	topMenuItemIndex: PropTypes.number.isRequired,
	headerLabel: PropTypes.string.isRequired,
	itemsData: PropTypes.oneOfType([PropTypes.array])
};

export default MMColumnSection;
