import React from 'react';
import { usePrefs } from 'wsm-common-data';
import { sanitizeLabelHtml } from '../utils/sanitizeLabelHtml';

const NavIcon = ({ label }) => {
	const { childListIconStyle, showChildIcons, childListIconPosition } =
		usePrefs();

	const positionClasses = {
		before: 'mr-2',
		after: 'ml-2'
	};

	const labelValue = sanitizeLabelHtml(label || '');

	const dropdownIconClasses = [
		'ddc-icon',
		`ddc-icon-${childListIconStyle}`,
		'ddc-nav-icon',
		positionClasses[childListIconPosition]
	].join(' ');

	const getInnerContent = () => {
		if (showChildIcons === 'true') {
			if (childListIconPosition === 'before') {
				return (
					<>
						<i className={dropdownIconClasses} />
						{labelValue}
					</>
				);
			} else if (childListIconPosition === 'after') {
				return (
					<>
						{labelValue}
						<i className={dropdownIconClasses} />
					</>
				);
			}
		}
		return labelValue;
	};

	return getInnerContent();
};

export default NavIcon;
