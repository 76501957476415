import React from 'react';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import Parser from 'html-react-parser';

const HTML_REGEX = /<[^>]*>|[#;]/g;

export const sanitizeLabelHtml = (label) => {
	if (HTML_REGEX.test(label)) {
		setNewRelicCustomAttribute('wsNavContainsHtml', label);
		const html = Parser(label);
		return <React.Fragment>{html}</React.Fragment>;
	}
	return label;
};
